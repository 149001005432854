import axios from 'axios';
import { APP_ID, ACCESS_TOKEN } from '../../pages/user/home/const';

const BOT_ID = 'cnchat_bot_1';
const CHAT_PATH = 'https://chat.eggdome.com';
const _channelDatas = [];
const _channelLimit = 100;

// 通用的 axios 实例
const axiosInstance = axios.create({
    baseURL: `https://api-${APP_ID}.sendbird.com/v3/`,
    headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Api-Token': ACCESS_TOKEN,
    },
});

// 错误处理函数
const handleRequest = async (request, callback) => {
    try {
        const response = await request;
        if (callback) callback(response);
        return response.data;
    } catch (error) {
        console.error('API Error:', error);
        if (callback) callback(error);
        throw error;  // 重新抛出错误以便调用者进一步处理
    }
};

/**
 * 递归获取所有的 channel
 */
export const getChannelDatas = async (user_id, next_token) => {
    let next_param = next_token ? '&token=' + next_token : '';
    const response = await handleRequest(
        axiosInstance.get(`users/${user_id}/my_group_channels?limit=${_channelLimit}&order=latest_last_message${next_param}&show_member=true`)
    );

    if (response.next !== '') {
        await getChannelDatas(user_id, response.next);
    }

    _channelDatas.unshift(...response.channels);
    return _channelDatas;
};

/**
 * 获取 channel 的详细信息
 */
export const getChannel = (channel_url, callback) => {
    handleRequest(
        axiosInstance.get(`group_channels/${channel_url}?show_member=true`),
        callback
    );
};

/**
 * 创建 channel
 */
export const createChannel = async (username, channelName, ad_user_all) => {
    let avatarUrl = getAvatar();
    const createRes = await handleRequest(
        axiosInstance.post('group_channels', {
            user_ids: [username, ...ad_user_all],
            name: channelName,
            cover_url: avatarUrl,
            is_distinct: true,
        })
    );

    // 加入到自动回复消息的 channel
    joinChannelBot(createRes.channel_url);
    return createRes;
};

/**
 * 加入到自动回复消息的 channel
 */
const joinChannelBot = (currentChannelUrl) => {
    handleRequest(
        axiosInstance.post(`bots/${BOT_ID}/channels`, {
            channel_urls: [currentChannelUrl],
        })
    );
};

/**
 * 更新 channel
 */
export const updateChannel = async (channel_url, data) => {
    const updateData = data || { cover_url: getAvatar() };
    return await handleRequest(
        axiosInstance.put(`group_channels/${channel_url}`, updateData)
    );
};

/**
 * 删除 channel
 */
export const deleteChannel = async (channel_url, callback) => {
    return await handleRequest(
        axiosInstance.delete(`group_channels/${channel_url}`),
        callback
    );
};

/**
 * 创建 open channel
 */
export const createOpenChannel = async (name, channel_url) => {
    return await handleRequest(
        axiosInstance.post('open_channels', { name, channel_url })
    );
};

/**
 * 获取 open channel 列表
 */
export const getOpenChannels = (callback) => {
    handleRequest(
        axiosInstance.get('open_channels'),
        callback
    );
};

/**
 * 获取用户信息
 */
export const getUsers = async (user_ids, callback) => {
    return await handleRequest(
        axiosInstance.get(`users?user_ids=${user_ids}`),
        callback
    );
};

/**
 * 创建用户
 */
export const createUser = async (user_id, nickname, callback) => {
    return await handleRequest(
        axiosInstance.post('users', {
            user_id,
            nickname: nickname || user_id,
            profile_url: '',
        }),
        callback
    );
};

/**
 * 获取 channel 成员
 */
export const getChannelMembers = (currentChannelUrl, callback) => {
    handleRequest(
        axiosInstance.get(`group_channels/${currentChannelUrl.channel_url}/members`),
        (res) => {
            const members = res.data.members.map((member) => member.user_id);
            callback(members);
        }
    );
};

/**
 * 邀请用户加入 channel
 */
export const inviteChannel = async (currentChannelUrl, members) => {
    return await handleRequest(
        axiosInstance.post(`group_channels/${currentChannelUrl.channel_url}/invite`, {
            user_ids: members,
        })
    );
};

/**
 * 用户离开 channel
 */
export const leaveChannel = (currentChannelUrl, members) => {
    return handleRequest(
        axiosInstance.put(`group_channels/${currentChannelUrl.channel_url}/leave`, {
            user_ids: members,
        })
    );
};

/**
 * 标记消息为已读
 */
export const setMsgAsRead = (channelUrl, users) => {
    users.forEach((user) => {
        handleRequest(
            axiosInstance.put(`users/${user}/mark_as_read_all`, {
                channel_urls: [channelUrl],
            })
        );
    });
};

/**
 * 获取消息列表
 */
export const getMessageDatas = (data, message_ts) => {
    return handleRequest(
        axiosInstance.get(`group_channels/${data.channel.channel_url}/messages`, {
            params: {
                prev_limit: data.prev_limit,
                next_limit: data.next_limit,
                message_ts,
                custom_types: '*',
                include_reactions: true,
                include_poll_details: false,
            },
        })
    );
};

/**
 * 获取 open channel 消息列表
 */
export const getOpenMessageDatas = (channel_url, admin_id, user_id, flag) => {
    const message_ts = Date.now();
    const sender_ids = `${admin_id},${user_id}`;
    const custom_types = flag !== '*' ? `flag@${flag};user_id@${user_id}` : '*';

    return handleRequest(
        axiosInstance.get(`open_channels/${channel_url}/messages`, {
            params: {
                prev_limit: 200,
                message_ts,
                sender_ids,
                custom_types,
                include_reactions: true,
                include_poll_details: false,
            },
        })
    );
};

/**
 * 请求自动回复消息
 */
export const sendBotMessage = (messageData) => {
    handleRequest(
        axiosInstance.post(`bots/${BOT_ID}/send`, messageData)
    );
     
/* 
    axiosInstance.post(`bots/${BOT_ID}/send`, messageData)
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        if (error.response) {
        // 请求已经发出，服务器响应了状态码，但返回了一个错误
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        } else if (error.request) {
        // 请求已经发出，但没有收到响应
        console.log(error.request);
        } else {
        // 其他错误，如设置问题
        console.log('Error', error.message);
        }
    });
 */
};

/**
 * 发送消息
 */
export const sendMessageHandle = (channel_url, messageData, callback) => {
    handleRequest(
        axiosInstance.post(`group_channels/${channel_url}/messages`, messageData),
        callback
    );
};

/**
 * 发送 open channel 消息
 */
export const sendOpenMessageHandle = (channel_url, messageData, callback) => {
    handleRequest(
        axiosInstance.post(`open_channels/${channel_url}/messages`, messageData),
        callback
    );
};

/**
 * 删除消息
 */
export const deleteMessage = (channel_url, message_id) => {
    handleRequest(
        axiosInstance.delete(`group_channels/${channel_url}/messages/${message_id}`)
    );
};

/**
 * 获取随机头像
 */
const getAvatar = () => {
    const rangeNum = 75;
    let avatarNum = Math.ceil(Math.random() * rangeNum);
    avatarNum = avatarNum < 10 ? '0' + avatarNum : '' + avatarNum;
    return `${CHAT_PATH}/avatar/${avatarNum}.png`;
};
