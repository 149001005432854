import React, { useState, useEffect, useRef } from 'react';
import ConsultingOrders from "./ConsultingOrders";
import OtherOrders from "./OtherOrders";
import { ChatActions } from "../../../actions/common/ChatActions";

function MyOrders({ tenantId, userId }) {
  const [options, setOptions] = useState({
    page: 1,
    pageSize: 20,
    order_time: ["2024-09-1 13:41:15", "2024-09-14 23:41:15"],
    tenantId,
  });

  const [otherOptions, setOtherOptions] = useState({
    page: 1,
    pageSize: 20,
    comprehensive: "",
    order_time: [],
    tenantId,
  });

  const [selectedCategory, setSelectedCategory] = useState('consulting');
  const [results, setResults] = useState([]);
  const [otherResults, setOtherResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const fetchOrders = async () => {
    if (loading) return;
    setLoading(true);
    try {
      console.log(options);
      const res = await ChatActions.getOrderInfo(options);
      if (res && res.data && Array.isArray(res.data.items)) {
        setResults((prevResults) => [...prevResults, ...res.data.items]);
      }
    } catch (error) {
      console.error(`数据加载失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchOtherOrders = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ChatActions.getOrderInfo(otherOptions);
      if (res) {
        console.log(res);
        setOtherResults((prevResults) => [...prevResults, ...res.data.items]);
      }
    } catch (error) {
      console.error(`数据加载失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (selectedCategory === 'consulting') {
      fetchOrders();
    }
  }, [options, selectedCategory]);


  useEffect(() => {
    // 只在点击搜索时触发
    if (selectedCategory === 'other') {        
      setOtherResults([]);
      fetchOtherOrders();
    }
  }, [otherOptions]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setResults([]);
    setOtherResults([]);
    setOptions((prev) => ({ ...prev, page: 1 }));
    setOtherOptions((prev) => ({ ...prev, page: 1, comprehensive: '', order_time: []})); // 重置其他选项
  };

  return (
    <div className="scrollable-content">
      <div className="t10 b10 l30">
        <button
          className={selectedCategory === 'consulting' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('consulting')}
        >
          正在咨询的订单
        </button>
        <button
          className={selectedCategory === 'other' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('other')}
        >
          其他订单
        </button>
      </div>

      {selectedCategory === 'consulting' && (
        <ConsultingOrders
          options={options}
          setOptions={setOptions}
          fetchOrders={fetchOrders}
          loading={loading}
          results={results}
        />
      )}

      {selectedCategory === 'other' && (
        <OtherOrders
          otherOptions={otherOptions}
          setOtherOptions={setOtherOptions}
          fetchOtherOrders={fetchOtherOrders}
          loading={loading}
          otherResults={otherResults}
        />
      )}
    </div>
  );
}

export default MyOrders;
