import React, { useState } from "react";
import { connect } from 'react-redux';
import { sendBotMessage } from '../actions/common/sendbird';
import { message as antdMessage, Button } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { Card, CardHeader, CardContent, Typography, Avatar } from "@mui/material";
import { ChatActions } from "../actions/common/ChatActions";

function BotMessage(props) {
  const { message } = props;
  const channel = props.current_cnchannel;
  const [feedback, setFeedback] = useState(null);

  const nickname = message.sender ? message.sender.nickname : '';
  const index = message.data;
  const [mode, setMode] = useState('chatbot'); // 当前模式，默认为 'chatbot'

  const data = JSON.parse(localStorage.getItem('faqsData'));

  const styles = {
    button: {
      width: '100%',
      textAlign: 'left',
      border: 'none',
    }
  };
  const findRelatedQuestionsById = (id) => {
    for (const category of data) {
      for (const question of category.frequently_asked_questions) {
        if (question.id === id) {
          return question.related_questions;
        }
      }
    }
    return []; // 如果没有找到，返回空数组
  };
  // 处理用户反馈
  const handleFeedback = async (index, feedbackType) => {
    setFeedback(feedbackType);

    //const feedbackMessage = feedbackType === 'like' ? '谢谢!' : '谢谢!';
    //antdMessage.success(feedbackMessage);
    console.log(`Message ${index} received feedback: ${feedbackType}`);

    let result = null;
    try {
      if(feedbackType === 'like')
        result = await ChatActions.likeVote(index);
      else
        result = await ChatActions.disLikeVote(index);

      if (result.status === '200') {
        console.log(result);
      } else {
        console.error(`channel url 接口调用失败: ${result.message}`);
      }
    } catch (error) {
      console.error(`Sendbird 初始化失败: ${error.message}`);
    }

  };
  // 发送Noti函数
  const sendNotiMessage = (text) => {
    const messageParams = {
      "message": text,
      "channel_url": channel._url,
      "custom_type": "bot_notification"
    };
  
    try {
      const sentMessage = sendBotMessage(messageParams);
      console.log('Message sent by cnchat_bot_1:', sentMessage);
    } catch (error) {
      console.error('Failed to send notification by cnchat_bot_1:', error);
    }
  };

    // 切换为人工模式
    const switchToHumanMode = async () => {
      setMode('human');
      //antdMessage.info('已切换到人工模式。');
      const groupInfo = await ChatActions.groupAssign(props.cnuuid);
      if (groupInfo.status === '200')
      {
        console.log(groupInfo);
        sendNotiMessage(groupInfo.data.nickname);
      }
    };

  return (

    <div className="admin-message">
      <Card sx={ { boxShadow : 'none' } } >

        <CardContent>
                    <ul className="ufl t10">
                      <li><div className="icon2">{ <Avatar alt="Us" src={message.sender.plainProfileUrl} /> }</div></li>
                      <li >
                        <strong className="cont_text2">{ nickname }</strong> <span className="span1">机器人</span>
                        <div className="t08"></div>


                        <div className="bgd1">
                        {message.message}
                        </div>
                        <div className="t08"></div>
                        <div className="bgd1">

{/*                           
                          猜你想问以下问题
                          <div className="t05"></div>
                          <a href="#" className="link1">商品有质量问题，可以退款吗？</a>
                          <a href="#" className="link1">退货费用是谁承担？</a>
                          <div className="t08">以上都不是</div>
 */}
                          
                          <div className="t05"></div>
                          
                          <Button type="link" className="button1" style={styles.button} onClick={switchToHumanMode}>담당자연결</Button>
                        </div>
{/*                         
                        <div style="position: absolute; bottom:0px; right: -100px;">
                          <a href="#"><img src="img/btn1_of.png" alt=""></a>
                          <a href="#"><img src="img/btn1_on.png" alt=""></a>
                          <a href="#"><img src="img/btn2_of.png" alt=""></a>
                          <a href="#"><img src="img/btn2_on.png" alt=""></a>
                        </div>
 */} 
<div className="feedback-container">
<Button icon={<LikeOutlined />} onClick={() => handleFeedback(index, 'like')} 
    style={{  fontSize: '20px', border: 'none',
              cursor: feedback ? 'not-allowed' : 'pointer',
              color: feedback === 'like' ? 'red' : 'black',
    }}
    disabled={!!feedback}
    />
<Button icon={<DislikeOutlined />} onClick={() => handleFeedback(index, 'dislike')} 
    style={{  fontSize: '20px', border: 'none',
              cursor: feedback ? 'not-allowed' : 'pointer', 
              color: feedback === 'dislike' ? 'blue' : 'black',
    }} 
    disabled={!!feedback}
    />


</div>

                      </li>
                    </ul>




        </CardContent>
      </Card>
    </div>

    
  );
}

// 从 Redux 中映射 state 到 props
const mapStateToProps = ({
  cnchannelReducer: { current_cnchannel },
  cnuserReducer: { cnuuid }
}) => ({
  current_cnchannel,
  cnuuid
});

export default connect(mapStateToProps)(BotMessage);
