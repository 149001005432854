import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import MyProductList from './MyProductList';

function ConsultingProducts({ options, setOptions, fetchProducts, loading, results }) {
  const resultsContainerRef = useRef(null);

  const handleScroll = () => {
    const container = resultsContainerRef.current;
    if (container && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setOptions((prev) => ({
          ...prev,
          page: prev.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = resultsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading]);

  return (
    <div ref={resultsContainerRef} style={{ maxHeight: '79vh', overflowY: 'auto' }}>
      {results.length > 0 ? (
        <MyProductList data={results} />
      ) : (
        <div style={{ textAlign: 'center' }}>...</div>
      )}
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

export default ConsultingProducts;