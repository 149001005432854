import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import MyProductList from './MyProductList';

function OtherProducts({ otherOptions, setOtherOptions, fetchOtherProducts, loading, otherResults }) {
    
  const [lavel, setLavel] = useState('3');
  const resultsContainerRef = useRef(null);

  // 滚动加载下一页
  const handleScroll = () => {
    const container = resultsContainerRef.current;
    if (container && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setOtherOptions((prev) => ({
          ...prev,
          page: prev.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = resultsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading]);

  // 处理搜索按钮点击
  const handleSearch = () => {
    // 清空之前的搜索结果
    setOtherOptions((prev) => ({
      ...prev,
      //searchContent: '',
      page: 1, // 重置页码
    }));
    
    // 调用查询函数
    fetchOtherProducts(otherOptions.searchContent);
  };

  // 处理标签类型变化
  const handleLabelChange = (type, value) => {
    
    setLavel(value);

    if (value === '3') { // 全部
        setOtherOptions((prev) => ({
          ...prev,
          labelType: '', 
          waterTagType: '1,2',
        }));
    } else if (value === '4') { // 品牌水洗标
        setOtherOptions((prev) => ({
          ...prev,
          labelType: '', 
          waterTagType: '1', // 设置对应的 waterTagType
        }));
    } else if (value === '5') { // 成分水洗标
        setOtherOptions((prev) => ({
          ...prev,
          labelType: '',
          waterTagType: '2', // 设置对应的 waterTagType
        }));
      } else {
        setOtherOptions((prev) => ({
          ...prev,
          labelType: value,
          waterTagType: '1,2',
        }));
      }



    setOtherOptions((prev) => ({ ...prev, [type]: Number(value) }));
  };

  return (
    <div ref={resultsContainerRef} style={{ maxHeight: '79vh', overflowY: 'auto' }}>
      <div className="bgd1_3">
        <table className="table_01">
          <caption>搜索表格</caption>
          <tbody>
            <tr>
              <td style={{ width: '65px' }}>综合搜索</td>
              <td className="l10">
                <input
                  type="text"
                  name="text"
                  className="input1"
                  placeholder="输入商品ID/名称/链接/..."
                  value={otherOptions.searchContent}
                  onChange={(e) => setOtherOptions((prev) => ({ ...prev, searchContent: e.target.value }))}
                />
              </td>
            </tr>
            <tr>
              <td>商品类型</td>
              <td className="l10">
                <select
                  name="select"
                  className="select1"
                  value={otherOptions.ptype}
                  onChange={(e) => setOtherOptions((prev) => ({ ...prev, ptype: Number(e.target.value) }))}
                >
                  <option value="0">全部</option>
                  <option value="1">在线商品</option>
                  <option value="2">线下商品</option>
                  <option value="3">套装商品</option>
                  <option value="4">淘宝</option>
                  <option value="5">天猫</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>标签类型</td>
              <td className="l10">
                <select
                  className="select1"
                  value={lavel} // 使其初始值为空
                  onChange={(e) => handleLabelChange('labelType', e.target.value)}
                >
                  <option value="3">全部</option>
                  <option value="2">商品标签</option>
                  <option value="1">食品标签</option>
                  <option value="0">无标签</option>
                  <option value="4">品牌水洗标</option>
                  <option value="5">成分水洗标</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="ac t10">
        <button
          className="button2"
          type="button"
          style={{ height: '30px', width: '60px' }}
          onClick={handleSearch} // 点击搜索按钮触发查询
        >
          搜索
        </button>
      </div>

      {otherResults.length > 0 ? (
        <MyProductList data={otherResults} />
      ) : (
        <div style={{ textAlign: 'center' }}>...</div>
      )}
      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}

export default OtherProducts;
