import React, { useState, useEffect, useRef } from 'react';
import ConsultingRecentViews from './ConsultingRecentViews';
import OtherProducts from './OtherProducts';
import { ChatActions } from "../../../actions/common/ChatActions";

function MyRecentViews({ tenantId, userId }) {
  const [options, setOptions] = useState({
    page: 1,
    pageSize: 20,
    tenantId,
    userId,
  });

  const [otherOptions, setOtherOptions] = useState({
    page: 1,
    pageSize: 20,
    tenantId,
    userId,
    searchContent: '',
    ptype: 0,
    labelType: 1,
    waterTagType: 1,
  });

  const [selectedCategory, setSelectedCategory] = useState('consulting');
  const [results, setResults] = useState([]);
  const [otherResults, setOtherResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const fetchProducts = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ChatActions.getRecentlyViewed(options);
      if (res && res.data && Array.isArray(res.data.items)) {

        setResults((prevResults) => [...prevResults, ...res.data.items]);
      }
    } catch (error) {
      console.error(`数据加载失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchOtherProducts = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await ChatActions.getProductInfo(otherOptions);
      if (res) {
        setOtherResults((prevResults) => [...prevResults, ...res.data.items]);
      }
    } catch (error) {
      console.error(`数据加载失败: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // 标记为非首次渲染
      return; // 初次渲染时不执行 fetchProducts
    }

    // 仅在选定类别变化时调用 fetchProducts
    if (selectedCategory === 'consulting') {
      fetchProducts();
    }
  }, [options, selectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setResults([]);
    setOtherResults([]);
    setOptions((prev) => ({ ...prev, page: 1 }));
    setOtherOptions((prev) => ({ ...prev, page: 1, searchContent: '', ptype: 0, labelType: 1, waterTagType: 1 })); // 重置其他选项
  };

  return (
    <div className="scrollable-content">
      <div className="t10 b10 l30">
        <button
          className={selectedCategory === 'consulting' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('consulting')}
        >
          正在咨询的商品
        </button>
        <button
          className={selectedCategory === 'other' ? 'button5_cur active' : 'button5'}
          type="button"
          onClick={() => handleCategoryChange('other')}
        >
          其他商品
        </button>
      </div>

      {selectedCategory === 'consulting' && (
        <ConsultingRecentViews
          options={options}
          setOptions={setOptions}
          fetchProducts={fetchProducts}
          loading={loading}
          results={results}
        />
      )}

      {selectedCategory === 'other' && (
        <OtherProducts
          otherOptions={otherOptions}
          setOtherOptions={setOtherOptions}
          fetchOtherProducts={fetchOtherProducts}
          loading={loading}
          otherResults={otherResults}
        />
      )}
    </div>
  );
}

export default MyRecentViews;
