import React, { useState } from 'react';
import { connect } from 'react-redux';
import {ChatActions} from "../../../actions/common/ChatActions";
import './css/productList.css';
//import MySKU from './MySKU';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const MyOrderList = (props) => {
  const { data, cnuuid, cnchannelurl } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);

  console.log(data);

  const handleChooseProduct = (products) => {
    setProducts(products);
    setIsModalOpen(true);
  };

  const handleSelectSku = (sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(sku) ? prevSelected.filter((s) => s !== sku) : [...prevSelected, sku]
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProducts([]);
  };

  const handleSendOrder = async (order) => {
    const params = {
        message: '',
        message_type: 'MESG',
        user_id: cnuuid,
        custom_type: 'myorder',
        data: JSON.stringify(order)
    }

    console.log(cnchannelurl);
    await  ChatActions._sendMsg(cnchannelurl, params)
  }
  const handleSendSKU = async (sku) => {
    const params = {
        message: '',
        message_type: 'MESG',
        user_id: cnuuid,
        custom_type: 'sku',
        data: JSON.stringify(sku)
    }
    console.log(sku);
    await  ChatActions._sendMsg(cnchannelurl, params)
    closeModal();
  }
  return (
    <div>
    {data && data.map(order => (
    <div className="bgd1_3">

<ul className="ufl l30" style={{ listStyle: 'none', padding: 10, margin: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
    

    <div className="l10">
      
    <div>订单编号 : {order.taskno} </div>
      <div className="t05">
        <span className="red">¥ {order.amount} </span>
        <span className="cont_text4 l15">总数量: {order.quantitynum} </span>
      </div>
      <div className="cb t15">
        <button className="button6" type="button" onClick={() => handleSendOrder(order)}>发送订单</button>

        {
        order.products &&
        <button className="button6" type="button" onClick={() => handleChooseProduct(order.products)}>发送商品</button>
        }
      </div>
    </div>
  </li>
</ul>


    <div className="cb"></div>

{/* 
    {isModalOpen && (
      
      <div className="modal">
        <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
              <MySKU skus={skus} closeModal={closeModal} cnuuid={cnuuid} cnchannelurl={cnchannelurl} />
        </div>
      </div>
    )}
 */}


  </div>
    ))}
</div>
  );
};

const mapStateToProps = ({
  cnuserReducer: { cnuuid, cnchannelurl }
}) => ({
  cnuuid,
  cnchannelurl
});
  
export default connect(mapStateToProps)(MyOrderList);