import { Requests } from "../../utils/CnRequest";
import { CnchatApi } from "../../utils/CnchatApi";
import { SendbirdApi } from "../../utils/sendbird.api";
import { SendbirdReq } from "../../utils/SendbirdReq";
import {message} from "antd";

export const ChatActions = {
    getChatInfo:async (data)=>{
        const response = await Requests.get({
            url: CnchatApi.cnchat_info,
            datas: data
        })

        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },
    
    getFaqsInfo:async ()=>{
        const response = await Requests.get({
            url: CnchatApi.faqs_info
        })

        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },

    unreadMessages:async (user_id)=>{
        const response = await Requests.get({
            url: CnchatApi.unread_messages(user_id)
        })
        
        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },

    groupAssign:async (user_id)=>{
        const response = await Requests.post({
            url: CnchatApi.group_assign(user_id)
        })

        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }

       return response;
    },
    viewCount:async (faq_id)=>{
        const response = await Requests.post({
            url: CnchatApi.view_count(faq_id)
        })
        
        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },
    likeVote:async (faq_id)=>{
        const response = await Requests.post({
            url: CnchatApi.like_vote(faq_id)
        })
        
        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },
    disLikeVote:async (faq_id)=>{
        const response = await Requests.post({
            url: CnchatApi.dislike_vote(faq_id)
        })
        
        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }
        
       return response;
    },

    /**
     * 获取sendbird用户数据（没有创建一个），并保存在本地
     * @param String app 
     * @param String username 
     */
    getSendbirdUser: async (username) => {
        let response;
        let datas = {
            'code': '',
            'message': 'SUCCESS'
        };

        try {
            response = await SendbirdReq.get({
                url: SendbirdApi.get_user(username)
            });
            console.log(response);
        } catch (err) {
            console.log(err);
            datas['code'] = err.response.data.code;
            datas['message'] = err.response.data.message;
            return datas;
        }
        localStorage.setItem('s_user', JSON.stringify(response));

        return datas;
    },
    _sendMsg: async (channel_url, data) => {
        return await SendbirdReq.post({
            url: SendbirdApi.messages(channel_url),
            datas: data
        });
    },
    /**
     * 最近浏览的商品
     * @returns {Promise<*>}
     */
    getRecentlyViewed: async (data) => {
        const response = await Requests.get({
            url: CnchatApi.recently_viewed,
            datas: data
        })


        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }

        return response;
    },
    /**
     * 我的商品库
     * @returns {Promise<*>}
     */
    getProductInfo: async (data) => {
        const response = await Requests.get({
            url: CnchatApi.product_info,
            datas: data
        })


        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }

        return response;
    },
    /**
     * 我的订单
     * @returns {Promise<*>}
     */
    getOrderInfo: async (data) => {
        const response = await Requests.getod({
            url: CnchatApi.order_info,
            datas: data
        })

        if (response.status !== "200") {
            if(response.status === 422){
                message.error(JSON.stringify(response.message))
            }else{
                message.error(response.message)
            }
            return false;
        }

        return response;
    }

}