import React, { useState } from 'react';
import { connect } from 'react-redux';
import {ChatActions} from "../../../actions/common/ChatActions";
import './css/productList.css';
import MySKU from './MySKU';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const MyProductList = (props) => {
  const { data, cnuuid, cnchannelurl } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [selectedSku, setSelectedSku] = useState(null);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [skus, setSkus] = useState([]);

  console.log(data);

  const handleChooseSKU = (skus) => {
    setSkus(skus);
    setIsModalOpen(true);
  };

  const handleSelectSku = (sku) => {
    setSelectedSkus((prevSelected) =>
      prevSelected.includes(sku) ? prevSelected.filter((s) => s !== sku) : [...prevSelected, sku]
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSkus([]);
  };

  const handleSendProduct = async (product) => {
    const params = {
        message: '',
        message_type: 'MESG',
        user_id: cnuuid,
        custom_type: 'myproduct',
        data: JSON.stringify(product)
    }

    console.log(cnchannelurl);
    await  ChatActions._sendMsg(cnchannelurl, params)
  }
  const handleSendSKU = async (sku) => {
    const params = {
        message: '',
        message_type: 'MESG',
        user_id: cnuuid,
        custom_type: 'sku',
        data: JSON.stringify(sku)
    }
    console.log(sku);
    await  ChatActions._sendMsg(cnchannelurl, params)
    closeModal();
  }
  return (
    <div>
    {data && data.map(product => (
    <div className="bgd1_3">
<ul className="ufl l30" style={{ listStyle: 'none', padding: 10, margin: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
    <a href={product.image} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
      <div className="img1" style={{ width: '60px', height: '60px', overflow: 'hidden' }}>
        <img
          src={product.image}
          alt={product.title}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }} // 保持比例裁剪
        />
      </div>
    </a>
    <div className="l10" style={{ width: 'calc(100% - 70px)' }}>
      <div>{product.title}</div>
      <div className="t05">
        <span className="red"> {product.refPrice} {product.priceUnit}</span>
        <span className="cont_text4 l15">库存: {product.canBookCount} {product.unit}</span>
      </div>
      <div className="cb t15">
        <button className="button6" type="button" onClick={() => handleSendProduct(product)}>发送商品</button>
        {
          product.skus &&
        <button className="button6" type="button" onClick={() => handleChooseSKU(product.skus)}>发送SKU</button>
        }
      </div>
    </div>
  </li>
</ul>


    <div className="cb"></div>

    {/* Modal for SKU details */}
    {isModalOpen && (

      
      <div className="modal">
        <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
              <MySKU skus={skus} closeModal={closeModal} cnuuid={cnuuid} cnchannelurl={cnchannelurl} />
{/*           
                            <ul className="sku-list">
                              {skus.map((sku) => (
                                <li key={sku.id} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedSkus.includes(sku)}
                                    onChange={() => handleSelectSku(sku)}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <div className="sku-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="img1" style={{ width: '60px', height: '60px', overflow: 'hidden', display: 'inline-block' }}>
                                      <img
                                        src={sku.skuImageUrl}
                                        alt={sku.attributeName}
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                      />
                                    </div>
                                    <div className="sku-info" style={{ marginLeft: '10px' }}>
                                      <div>{sku.attributeName}</div>
                                      <div>
                                        <span>{sku.canBookCount}</span>
                                        <span>{sku.price}</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
 */}

        </div>
      </div>


    )}
  </div>
    ))}
</div>
  );
};

const mapStateToProps = ({
  cnuserReducer: { cnuuid, cnchannelurl }
}) => ({
  cnuuid,
  cnchannelurl
});
  
export default connect(mapStateToProps)(MyProductList);