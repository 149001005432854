import React, { useState } from 'react';
import { Card, Avatar, CardContent } from "@mui/material";

import '../pages/user/side/css/productList.css';

const img_url = 'https://gw.alicdn.com/tfs//a7/3f/TB1ww3yLYrpK1RjSZTESuwWAVXa.jpg_320x320q75.jpg_.webp';

const MyProductMessage = (props) => {
	const {
		message,
		customerId,
	  } = props;
  const product = JSON.parse(message.data);
  return (
<div>
      <Card sx={ { boxShadow : 'none' } } >


<CardContent>

{(message.sender.userId === customerId) && (
  
<ul className="ufl t10 fr">
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <a href={product.image} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
    <li><div className="img1"><img src={product.image} alt={product.title} style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></div></li>
      <div className="l10" style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }}>
        <p style={{ margin: 0 }}>{product.title}</p>
        <div className="t05">
          <span className="red r20">{product.refPrice} {product.priceUnit}</span>
          <span className="cont_text4">库存: {product.canBookCount} {product.unit}</span>
        </div>
      </div>
    </a>
  </li>
</ul>

<div className="cb"></div>
				</div>
			</li>
			<li><div className="icon2"><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
		</ul>

)}

{!(message.sender.userId === customerId) && (

<ul className="ufl t20">

<li><div className="icon2" style={{'margin-left': '8px'}}><Avatar alt="" src={message.sender.plainProfileUrl} /></div></li>
			<li>
				<div className="bgd1_2">


<ul className="ufl" style={{ listStyleType: 'none', padding: 0 }}>
  <li style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <a href={product.image} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
    <li><div className="img1"><img src={product.image} alt={product.title} style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></div></li>
      <div className="l10" style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }}>
        <p style={{ margin: 0 }}>{product.title}</p>
        <div className="t05">
          <span className="red r20">{product.refPrice} {product.priceUnit}</span>
          <span className="cont_text4">库存: {product.canBookCount} {product.unit}</span>
        </div>
      </div>
    </a>
  </li>
</ul>

<div className="cb"></div>
				</div>
			</li>
		</ul>




)}
</CardContent>

</Card>
    </div>


  );
};


export default MyProductMessage;