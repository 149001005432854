import axios from "axios";
import { jsonToUrlParam } from "./functions";

export const Requests = {

    /**
     * 组合 header 数据
     * @param params
     * @returns {{Accept: string, "Content-Type": string}}
     */
    headers: (params) => {
        let extraHeader = params.extraHeader ? params.extraHeader : {};

        let headerData = {
            'Content-Type': 'application/json; charset=utf8',
            'Accept': 'application/json; charset=UTF-8',
            ...extraHeader,
        }

        if (params.withAuth) {
            let token = JSON.parse(localStorage.getItem('token'));
            let access_token = token ? token.access_token : '';
            let authorization = 'bearer ' + access_token;

            headerData['Authorization'] = authorization;
        }

        return headerData;
    },

    /**
     * GET 模式请求 api
     * @param params
     * @returns {Promise<any>}
     */
    get: async (params) => {

        let url = params.url;
        if (params.datas) {
            console.log(params.datas);
            url += '?' + jsonToUrlParam(params.datas);
        }

        let result = await axios.get(
            url,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        )

        return  result.data;
    },
    getod: async (params) => {

        let url = params.url;
        if (params.datas) {
            url += '?' + jsonToUrlParam(params.datas);
        }

        let result = await axios.get(
            url,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        )

        return  result.data;
    },
    /**
     * POST 模式请求 api
     * @param params
     * @returns {Promise<any>}
     */
    post: async (params) => {

        let result = await axios.post(
            params.url,
            params.datas,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        );

        return  result.data;
    },

    /**
     * PUT 模式请求 api
     * @param params
     * @returns {Promise<any>}
     */
    put: async (params) => {

        let result = await axios.put(
            params.url,
            params.datas,
            {headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})}
        );

        return  result.data;
    },

    /**
     * DELETE 模式请求 api
     * @param params
     * @returns {Promise<any>}
     */
    delete: async (params) => {

        let param = params.datas ? params.datas : {}
        let result = await axios.delete(
            params.url,
            {
                data: param,
                headers: Requests.headers({extraHeader: params.headers, withAuth: params.withAuth})
            }
        );

        return  result.data;
    }
}